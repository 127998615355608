import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "../styles/global.scss"
import Seo from "../components/Seo"
import { GrInstagram } from "react-icons/gr"
import { useState } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
export default function Home() {
  const [navpop, setNavpop] = useState(false)

  const hamburg = (
    <div className="hamburg-container">
      <motion.svg
        initial={{ x: 50 }}
        animate={{ x: 0 }}
        transition={{ duration: 2, delay: 1 }}
        onClick={() => setNavpop(!navpop)}
        className="hamburg"
        width="49"
        height="51"
        viewBox="0 0 49 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.0864258 25.6278H48.5864"
          stroke="#F7F7F7"
          stroke-width="4"
        />
        <path
          id="middle-hamburg"
          d="M0 2H48.5"
          stroke="#F7F7F7"
          stroke-width="4"
        />
        <path
          id="bottom-hamburg"
          d="M0 49H48.5"
          stroke="#F7F7F7"
          stroke-width="4"
        />
      </motion.svg>
    </div>
  )

  const popUpNav = (
    <div className="popUpList">
      <div className="popUpContainer">
        <Link onClick={() => setNavpop(!navpop)} className="navLink" to="/">
          Home
        </Link>
        <Link
          onClick={() => setNavpop(!navpop)}
          className="navLink"
          to="/Concerts"
        >
          Concerts{" "}
        </Link>
        <Link
          onClick={() => setNavpop(!navpop)}
          classname="navLink"
          to="/Portraits"
        >
          Portraits
        </Link>
        <Link
          onClick={() => setNavpop(!navpop)}
          classname="navLink"
          to="/Weddings"
        >
          Weddings
        </Link>
        <Link
          onClick={() => setNavpop(!navpop)}
          classname="navLink"
          to="/Travel"
        >
          Travel
        </Link>
        <Link
          onClick={() => setNavpop(!navpop)}
          to="/Contact"
          style={{ color: "#fbc89a" }}
        >
          Contact
        </Link>
      </div>
    </div>
  )
  const closeIcon = (
    <svg
      className="closeIcon"
      onClick={() => setNavpop(!navpop)}
      viewBox="0 0 50 50"
      width="50"
      height="25"
      overflow="visible"
      stroke-width="6"
      stroke-linecap="round"
    >
      <line x1="0" y1="0" x2="50" y2="50" />
      <line x1="50" y1="0" x2="0" y2="50" />
    </svg>
  )
  return (
    <motion.div
      className="main-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.3, duration: 1.7, type: "tween" }}
    >
      <Seo />
      <StaticImage
        className="background-image"
        src="../images/alex-big-bg.png"
        layout="fullWidth"
        alt="cavern"
        placeholder="tracedSVG"
        quality={100}
        loading="lazy"
      />
      <div className="name-container">
        <h1>Alex Mason</h1>
        <div className="flex-container">
          <div className="instagram-link">
            <a
              aria-label="Instagram"
              target="_blank"
              href="
            https://www.instagram.com/alexjmason/ 
            
            "
            >
              <GrInstagram className="instFoot" fill="white" size={22} />
            </a>
          </div>
          <h2>Photography</h2>
        </div>
      </div>

      {navpop ? closeIcon : hamburg}
      {navpop ? popUpNav : ""}
    </motion.div>
  )
}
